import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home, NotFound } from '../../pages';

const Body = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default Body;
