import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    useScrollTrigger,
    Slide,
    useTheme,
    Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';

const Header = () => {
    const trigger = useScrollTrigger();
    const theme = useTheme();
    return (
        <>
            <Slide appear={false} direction="down" in={!trigger}>
                <AppBar sx={{ bgcolor: theme.palette.appWhite }}>
                    <Toolbar>
                        <Grid container>
                            <Grid item xs={5}>
                                Button 1
                            </Grid>
                            <Grid item xs={2}>
                                <Link to="/" style={{ textDecoration: 'none' }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        color="primary"
                                    >
                                        Logo Here{' '}
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={5}>
                                Button 2
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Slide>
            <Toolbar />
        </>
    );
};

export default Header;
