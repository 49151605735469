import { createTheme, ThemeOptions, responsiveFontSizes } from '@mui/material';

const primaryColor = '#FF2400';
const secondaryColor = '#013766';
const backgroundColor = '#FFFFFF';
const appWhiteColor = '#FFFFFF';
const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: secondaryColor,
        },
        background: {
            default: backgroundColor,
        },
        appWhite: appWhiteColor,
    },
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    ':before': {
                        borderTopColor: primaryColor,
                    },
                    ':after': {
                        borderTopColor: primaryColor,
                    },
                },
            },
        },
    },
};

const theme = createTheme(themeOptions);

const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;
