import React from 'react';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Divider,
    useTheme,
    Box,
} from '@mui/material';
import googleSearchImage from '../assets/google-search.png';

const Home = () => {
    const theme = useTheme();
    return (
        <>
            <Container fixed>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid
                            container
                            alignItems="center"
                            spacing={2}
                            sx={{ mt: 0 }}
                        >
                            <Grid item sm={6} xs={12}>
                                <Card raised>
                                    <CardMedia
                                        component="img"
                                        image={googleSearchImage}
                                        alt="google search"
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            color="secondary"
                                        >
                                            Stand Out
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            Today, having a website is just as
                                            important as a physical sign in
                                            front of a store.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h5" color="secondary">
                                    Boost your online presence and reach with a
                                    simple, modern, affordable, and custom
                                    website.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Box
                sx={{
                    width: '100%',
                    background: `linear-gradient(0.25turn, ${theme.palette.secondary.main}, ${theme.palette.secondary.light}, ${theme.palette.secondary.main})`,
                    mt: theme.spacing(4),
                    mb: theme.spacing(4),
                    pt: theme.spacing(4),
                    pb: theme.spacing(4),
                }}
            >
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography color="appWhite" variant="h4">
                                Your time is money.
                            </Typography>
                            <Typography color="appWhite" variant="h6">
                                {`Don't waste countless hours with a cookie cutter website builder when you can be running your business.`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Container fixed>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}>
                                <Grid item>
                                    <Typography color="secondary" variant="h5">
                                        $500 + $30 / month
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Divider variant="middle">
                                        <Typography
                                            color="primary"
                                            variant="h6"
                                        >
                                            BASIC
                                        </Typography>
                                    </Divider>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        color="secondary"
                                        variant="body1"
                                    >
                                        Custom development of single page
                                        website and support for content updates.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid item>
                                    <Typography color="secondary" variant="h5">
                                        $500 + $60 / month
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Divider variant="middle">
                                        <Typography
                                            color="primary"
                                            variant="h6"
                                        >
                                            PREMIUM
                                        </Typography>
                                    </Divider>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        color="secondary"
                                        variant="body1"
                                    >
                                        BASIC + search engine optimization,
                                        domain management, up to 3 email
                                        accounts.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid item>
                                    <Typography color="secondary" variant="h5">
                                        contact us
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Divider variant="middle">
                                        <Typography
                                            color="primary"
                                            variant="h6"
                                        >
                                            CUSTOM
                                        </Typography>
                                    </Divider>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        color="secondary"
                                        variant="body1"
                                    >
                                        Have other needs? There is a lot we can
                                        do. Reach out and see if we would be a
                                        good fit.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item>
                                <Typography variant="h5" color="secondary.main">
                                    {`Less is more when making digital first
                                    impressions. We will work with you to build
                                    for all screen sizes and make your
                                    business's digital footprint shine.`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Box
                sx={{
                    width: '100%',
                    background: `linear-gradient(0.25turn, ${theme.palette.secondary.main}, ${theme.palette.secondary.light}, ${theme.palette.secondary.main})`,
                    mt: theme.spacing(4),
                    mb: theme.spacing(4),
                    pt: theme.spacing(4),
                    pb: theme.spacing(4),
                }}
            >
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography color="appWhite" variant="h4">
                                Your business is unique.
                            </Typography>
                            <Typography color="appWhite" variant="h6">
                                Your website should be too.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Container fixed>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} md={6} lg={4}>
                                <Divider variant="middle">
                                    <Typography color="primary" variant="h6">
                                        CONTACT US TODAY
                                    </Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="secondary" variant="body1">
                                    support@cardinaliswebdevelopment.com
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Home;
