import React from 'react';
import { Paper, Grid, Typography, IconButton, useTheme } from '@mui/material';
import { Twitter, Facebook, Instagram } from '@mui/icons-material';

const Footer = () => {
    const now = new Date();
    const year = now.getFullYear();
    const theme = useTheme();

    const facebookUrl = process.env.REACT_APP_FACEBOOK_URL;
    const twitterUrl = process.env.REACT_APP_TWITTER_URL;
    const instaUrl = process.env.REACT_APP_INSTAGRAM_URL;
    const iconColor = theme.palette.appWhite;

    return (
        <Paper
            elevation={20}
            square
            sx={{
                minHeight: '5rem',
                backgroundColor: 'secondary.main',
                width: '100%',
            }}
        >
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                height="5rem"
                spacing={1}
            >
                <Grid item>
                    <Typography color="appWhite" variant="body1">
                        {`\u00A9 ${year} Cardinalis Web Development`}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                {facebookUrl && (
                                    <Grid item>
                                        <a href={facebookUrl}>
                                            <IconButton
                                                sx={{
                                                    color: iconColor,
                                                }}
                                            >
                                                <Facebook />
                                            </IconButton>
                                        </a>
                                    </Grid>
                                )}
                                {instaUrl && (
                                    <Grid item>
                                        <a href={instaUrl}>
                                            <IconButton
                                                sx={{
                                                    color: iconColor,
                                                }}
                                            >
                                                <Instagram />
                                            </IconButton>
                                        </a>
                                    </Grid>
                                )}
                                {twitterUrl && (
                                    <Grid item>
                                        <a href={twitterUrl}>
                                            <IconButton
                                                sx={{
                                                    color: iconColor,
                                                }}
                                            >
                                                <Twitter />
                                            </IconButton>
                                        </a>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Footer;
