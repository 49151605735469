import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const NotFound = () => {
    return (
        <Container fixed>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h2" color="secondary">
                        Ope...
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                        Page not found.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" color="secondary">
                        There is nothing here.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotFound;
